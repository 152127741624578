// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import TreeView from '../../../../components/treeview';
import { cpGetAllInitialFolderChange, cpFolderReset, cpUpdateFolder } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import { closeAll } from '../../../../components/treeview/react-simple-jstree';
import { manageTreeData, removeManageTreeId } from '../../../../helpers/genericFunction';

const SelectNewLocationModal = (props) => {
    const [movedPath, setMovedPath] = useState(null);
    const { modalDispatch } = useModalContext();
    const [addfolderDisabled, setAddFolderDisabled] = useState(true);
    const [contentData, setContentData] = useState();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        localStorage.removeItem('SelectContentsJstreeRendaring');
        getInitialFolderList();
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.getElementById(parentId);
        const changeLocation = $('.changeLocation');
        if (ele) {
            const ele1 = changeLocation.find('#' + parentId);
            ele1.find('.jstree-icon.jstree-ocl').addClass('loading');
        }
        dispatch(cpGetAllInitialFolderChange({ processId: 13, parentId }));
    };

    const { initialList, authInfo } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolderChange,
        authInfo: state?.auth?.me,
    }));

    useEffect(() => {
        if (initialList?.data) {
            dispatch(cpFolderReset());
            setTimeout(() => {
                setLoading(false);
            }, 1000);
            async function createInitialData() {
                // await manageTreeData(data);
                await manageTreeData(
                    initialList?.data,
                    setData,
                    data,
                    'SelectContentsJstreeRendaring',
                    movedPath,
                    setMovedPath,
                    getInitialFolderList,
                    props?.data?.data
                );
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    if (el?.text === 'RECYCLE BIN') return;
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                        fkCpRootNodeId: el.fkCpRootNodeId,
                        cpContentId: el.cpContentId,
                    });
                });
                setData([...data]);
            }
            dispatch(cpFolderReset());
        }
    }, [initialList]);

    const handleGetList = (e, list) => {
        const id = list?.node?.original?.id;
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(id);
        }
        setContentData({
            ...list?.node?.original,
            parentRootId: list?.node?.parent,
            currentId: id,
        });
        if (list?.node?.original?.id) {
            const CpFile =
                (props?.data?.pageName === 'Content-panel' && parseInt(id) === parseInt(props.data?.data?.parent_id)) ||
                false;
            if (
                (list?.node.parent === '#' && list?.node?.original.text === 'SYSTEM CLOUD') ||
                list?.node?.original?.id === props?.data?.data?.id
            ) {
                props?.data?.pageName === 'edit-content-panel' || CpFile
                    ? setAddFolderDisabled(true)
                    : setAddFolderDisabled(false);
                if (
                    props?.data?.data?.icon == 'glyphicon glyphicon-text-background-cp' ||
                    props?.data?.data?.icon == 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
                    props?.data?.data?.icon == 'glyphicon glyphicon-text-background-cp-printable' ||
                    props?.data?.data?.icon == 'glyphicon glyphicon-text-background-cp-centrally-managed-printable'
                ) {
                    setAddFolderDisabled(true);
                }
            } else if (list?.node?.original.text === 'PRIMARY') {
                setAddFolderDisabled(false);
            } else if (
                (list?.node?.original.icon == 'mdi mdi-cloud' && list?.node?.original.text == 'BRAND CLOUD') ||
                list?.node?.original.icon === 'mdi mdi-folder-lock'
            ) {
                setAddFolderDisabled(true);
            } else if (list?.node?.original.icon === 'fa-fa-folder-brand-closed') {
                setAddFolderDisabled(false);
            } else if (
                list?.node?.original.icon === 'fa-fa-folder-palette-closed' ||
                list?.node?.original.icon === 'mdi mdi-folder-account'
            ) {
                setAddFolderDisabled(false);
            } else if (list?.node.icon === 'mdi mdi-folder') {
                setAddFolderDisabled(CpFile);
            } else if (list?.node.children.length) {
                setAddFolderDisabled(false);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-image' ||
                list?.node.icon === 'glyphicon glyphicon-doc-file' ||
                list?.node.icon === 'glyphicon glyphicon-video' ||
                list?.node.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                setAddFolderDisabled(true);
            }
        }
    };

    const handleClick = () => {
        closeAll();
        const parentId = contentData?.id;
        const disabledClass = 'disabled';
        removeManageTreeId('ContentsJstreeRendaring', props?.data?.data?.id);
        const formData = {
            id: props?.data?.data?.id,
            fkPaletteId: props?.data?.data?.fkPaletteId,
            fkRoleId: props?.data?.data?.fkRoleId || authInfo?.role?.id,
            name: props?.data?.data?.text,
            permissionbyId: props?.data?.data?.permissionbyId,
            userId: props?.data?.data?.userId || authInfo?.id,
            brandId: props?.data?.data?.brandId,
            canBeDeleted: props?.data?.data?.canBeDeleted,
            cpContentId: props?.data?.data?.cpContentId,
            parentId,
            disabledClass,
            icon: props?.data?.data?.icon,
            fkCpRootNodeId: props?.data?.data?.fkCpRootNodeId || props?.data?.data?.id,
        };
        let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
        localStorage.removeItem('activeJstreePath');
        props.data.setMovedPath({
            data: data,
            path: activeJstreePath,
            fromNodeParentId: props?.data?.data?.parent_id,
            fromNodeId: props?.data?.data?.id,
        });
        dispatch(cpUpdateFolder(formData));
        sessionStorage.setItem(
            process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
            props?.data?.data?.icon === 'mdi mdi-folder' ? 'Folder moved.' : 'Content Panel moved.'
        );
    };

    const handlePermission = () => {
        if (props?.data?.data?.icon === 'mdi mdi-folder') {
            props.data.data.setAddFolderDisabled();
            props.data.data.setFolderDisabled();
        } else {
            props.data.data.setContentPanelDisabled();
            props.data.data.setAddcontentPanelDisabled();
        }
    };

    return (
        <Row className="jstree-custom-style">
            {loading && (
                <div className="jstree-default">
                    <i className="loading jstree-icon jstree-ocl" />
                    <span className="loading-text">Loading...</span>
                </div>
            )}
            <TreeView handleChange={handleGetList} data={loading ? [] : data} identifier="changeLocation" />

            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={
                        props?.data?.pageName === 'deleteData-content-panel'
                            ? 'Select'
                            : props?.data?.pageName === 'edit-content-panel'
                            ? 'Publish'
                            : 'Move'
                    }
                    variant="outline-primary"
                    onClick={() => {
                        localStorage.removeItem('contentJstreeHistory');
                        if (props?.data?.pageName === 'deleteData-content-panel') {
                            //let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
                            localStorage.removeItem('activeJstreePath');
                            // props?.data?.handlePublish(contentData, {
                            //     data: data,
                            //     path: activeJstreePath,
                            // });
                            props?.data?.data?.handleData(contentData, props?.data?.data?.original);
                        } else if (props?.data?.pageName === 'edit-content-panel') {
                            let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
                            localStorage.removeItem('activeJstreePath');
                            props?.data?.handlePublish(contentData, {
                                data: data,
                                path: activeJstreePath,
                            });
                        } else {
                            modalDispatch({
                                type: modalActions.HIDE_MODAL,
                            });
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: handleClick,
                                    modalVariant: 'primary',
                                    modalDataKey: ['common', 'changeLocationConfirmation'],
                                    data: props?.data?.data?.icon === 'mdi mdi-folder' ? 'folder' : 'content-panel',
                                },
                            });
                        }
                    }}
                    disabled={addfolderDisabled}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.HIDE_MODAL,
                            payload: {
                                modalAction: handlePermission,
                            },
                        });
                    }}
                    disabled={loading}
                />
            </div>
        </Row>
    );
};

export default SelectNewLocationModal;
