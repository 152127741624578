// @flow
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
// component
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { assePreview, assetReset, assePreviewDoc, assetFolderViewTempDataSet } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { RedirectTo } from '../../../routes/role/path';
import { spacePreventDefault } from '../../../helpers/retainHistory';

const AssetPanelPreviewModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [assetUrl, setAssetUrl] = useState(null);
    const [assetUrlPDF, setAssetUrlPDF] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props?.data?.data?.icon === 'glyphicon glyphicon-pdf-file') {
            dispatch(assePreviewDoc({ assetFolderViewId: props?.data?.data?.id }));
        } else {
            dispatch(assePreview({ assetFolderViewId: props?.data?.data?.id }));
        }
    }, []);

    const { asset, previewDoc } = useSelector((state) => ({
        asset: state?.asset?.preview,
        previewDoc: state?.asset?.previewDoc,
    }));

    useEffect(() => {
        if (previewDoc && props?.data?.data?.icon === 'glyphicon glyphicon-pdf-file') {
            //window.open(previewDoc?.data, '_blank');
            setAssetUrlPDF(previewDoc?.data);
            dispatch(assetReset());
        }
    }, [previewDoc]);

    useEffect(() => {
        if (asset?.data) {
            setAssetUrl(asset?.data);
            dispatch(assetReset());
        }
    }, [asset]);

    document.addEventListener('keydown', (e) => {
        if (
            e.target.closest('.visually-visible-modal.modal') &&
            (window?.location?.pathname?.includes('/site-edit') ||
                window?.location?.pathname?.includes('/reportdata-edit-details') ||
                window?.location?.pathname?.includes('/reportdata-edit-impact-details') ||
                window?.location?.pathname?.includes('/reportdata-create-fund') ||
                window?.location?.pathname?.includes('/reportdata-edit-performance') ||
                window?.location?.pathname?.includes('/portfolio-edit-record') ||
                window?.location?.pathname?.includes('/report-edit')) &&
            (e.key == ' ' || e.code == 'Space' || e.keyCode == 32)
        ) {
            if (
                props?.data?.data?.icon === 'glyphicon glyphicon-image' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-video' ||
                props?.data?.data?.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                let isModalOpen = document?.querySelector('.modal .modal-title')?.innerText;
                if (
                    window?.location?.pathname?.includes('/site-edit') ||
                    window?.location?.pathname?.includes('/report-edit') ||
                    window?.location?.pathname?.includes('/reportdata-edit-details') ||
                    window?.location?.pathname?.includes('/reportdata-edit-performance')
                )
                    isModalOpen = document?.querySelector('.modal .asset-preview .modal-title')?.innerText;
                if (
                    (window?.location?.pathname === RedirectTo('/content-panel-edit') ||
                        window?.location?.pathname?.includes('/site-edit') ||
                        window?.location?.pathname?.includes('/report-edit')) &&
                    isModalOpen?.includes('Asset Preview')
                ) {
                    props?.hideModal();
                } else if (
                    (window?.location?.pathname?.includes('/reportdata-edit-impact-details') ||
                        window?.location?.pathname?.includes('/reportdata-create-fund')) &&
                    isModalOpen?.includes('Asset Preview')
                ) {
                    modalDispatch({
                        type: modalActions.OPEN_MODAL,
                        payload: {
                            modalAction: (e) => modalDispatch({ type: modalActions.CLOSE_MODAL }),
                            modalVariant: 'primary',
                            modalDataKey: ['common', 'replaceAsset'],
                            data: {
                                ...props?.data?.propsData,
                                contentData: props?.data?.contentData,
                                activeJstreePath: props?.data?.activeJstreePath,
                            },
                        },
                    });
                } else if (
                    window?.location?.pathname?.includes('/portfolio-edit-record') &&
                    document?.querySelector('.modal-dialog.asset-preview')
                ) {
                    props?.data?.setOpenModal && props?.data?.setOpenModal(false);
                    props?.data?.setPreviewModalOpen && props.data.setPreviewModalOpen(false);
                    setTimeout(() => {
                        let btn = document?.querySelector('.modal.visually-visible-modal .asset-preview button');
                        if (btn?.innerText === 'Back') btn.click();
                    }, 500);
                }
            }
            spacePreventDefault(e);
        }
    });

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="preview-box">
                        {props?.data?.data?.icon === 'glyphicon glyphicon-video' ? (
                            <>
                                {assetUrl && (
                                    <iframe
                                        className={assetUrl ? 'video-block' : ''}
                                        src={assetUrl}
                                        alt="preview"
                                        style={{
                                            display: assetUrl ? 'block' : 'none',
                                        }}></iframe>
                                )}
                            </>
                        ) : props?.data?.data?.icon === 'glyphicon glyphicon-pdf-file' ? (
                            <>
                                {assetUrlPDF && (
                                    <iframe
                                        className={assetUrlPDF ? 'video-block pdf-preview' : ''}
                                        src={assetUrlPDF}
                                        alt="preview"
                                        style={{
                                            display: assetUrlPDF ? 'block' : 'none',
                                        }}></iframe>
                                )}
                            </>
                        ) : (
                            <>{assetUrl && <img src={assetUrl} alt="preview image" />}</>
                        )}
                    </div>
                </Card.Body>
            </Card>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Back"
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                        props?.data?.setOpenModal && props?.data?.setOpenModal(false);
                        props?.data?.setPreviewModalOpen && props.data.setPreviewModalOpen(false);
                        let isModalOpen = document?.querySelector('.modal .modal-title')?.innerText;
                        if (
                            window?.location?.pathname?.includes('/site-edit') ||
                            window?.location?.pathname?.includes('/report-edit') ||
                            window?.location?.pathname?.includes('/reportdata-edit-details') ||
                            window?.location?.pathname?.includes('/reportdata-edit-performance') ||
                            window?.location?.pathname?.includes('/reportdata-add-impact-details') ||
                            window?.location?.pathname?.includes('/reportdata-create-fund') ||
                            window?.location?.pathname?.includes('/reportdata-edit-impact-details')
                        )
                            isModalOpen = document?.querySelector('.modal .asset-preview .modal-title')?.innerText;
                        if (
                            (window?.location?.pathname?.includes('/reportdata-edit-details') ||
                                window?.location?.pathname?.includes('/reportdata-edit-performance') ||
                                window?.location?.pathname?.includes('/reportdata-add-impact-details') ||
                                window?.location?.pathname?.includes('/reportdata-edit-impact-details') ||
                                window?.location?.pathname?.includes('/reportdata-create-fund')) &&
                            (props?.data?.data?.icon === 'glyphicon glyphicon-image' ||
                                props?.data?.data?.icon === 'glyphicon glyphicon-video') &&
                            isModalOpen?.includes('Asset Preview')
                        ) {
                            dispatch(
                                assetFolderViewTempDataSet({
                                    ...props?.data?.assetPreviewProps,
                                    contentData: props?.data?.contentData,
                                    activeJstreePath: props?.data?.activeJstreePath,
                                })
                            );
                            props?.hideModal();
                        } else if (
                            window?.location?.pathname?.includes('/reportdata-edit-details') ||
                            window?.location?.pathname?.includes('/reportdata-create-fund')
                        ) {
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => modalDispatch({ type: modalActions.CLOSE_MODAL }),
                                    modalVariant: 'primary',
                                    modalDataKey: ['common', 'replaceAsset'],
                                    data: {
                                        ...props?.data?.propsData,
                                        contentData: props?.data?.contentData,
                                        activeJstreePath: props?.data?.activeJstreePath,
                                    },
                                },
                            });
                        } else props?.hideModal();
                    }}
                />
            </div>
        </>
    );
};

export default AssetPanelPreviewModal;
