import PrivateRoute from '../PrivateRoute';
import { COMMON_PATH, SYSTEM_ADMIN_PATH } from '../role/path';
import {
    Administrators,
    AnalyticsSettings,
    ApiConnections,
    ApiConnectionMapDataField,
    Brands,
    BuilderTableDefaults,
    Communications,
    ManageapiKey,
    ManageGroups,
    Records,
    Reports,
    Settings,
    Sites,
} from './generalRoutes';

export const systemSettingsModule = {
    path: COMMON_PATH.SYSTEM_SETTINGS,
    name: 'SYSTEM SETTINGS',
    title: 'System Settings',
    exact: true,
    component: Settings,
    route: PrivateRoute,
    breadcrumbs: [{ label: 'System Settings', path: '/', active: true }],
    children: [
        {
            path: COMMON_PATH.API_CONNECTIONS,
            name: 'CONNECTED 3RD PARTY APPLICATIONS',
            title: 'Connected 3rd Party Applications',
            exact: true,
            component: ApiConnections,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Connected 3rd Party Applications', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.API_CONNECTIONS_MAP_DATA_FIELD,
            name: 'MAP DATA FIELDS',
            title: 'Map data fields',
            exact: true,
            component: ApiConnectionMapDataField,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                {
                    label: 'Connected 3rd Party Applications',
                    path: COMMON_PATH.API_CONNECTIONS,
                    active: false,
                },
                { label: 'Map data fields', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.ADMINISTRATORS,
            name: 'ADMINISTRATORS',
            title: 'Administrators',
            exact: true,
            component: Administrators,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Administrators', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.GROUPS,
            name: 'GROUPS',
            title: 'Groups',
            exact: true,
            component: ManageGroups,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Groups', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.BRAND_SETTINGS,
            name: 'BRAND SETTINGS',
            title: 'Brand Settings',
            exact: true,
            component: Brands,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Brands', path: '/', active: true },
            ],
        },
        {
            path: SYSTEM_ADMIN_PATH.RECORD_SETTINGS,
            name: 'RECORD SETTINGS',
            title: 'Records Settings',
            exact: true,
            component: Records,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Records', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.BUILDER_TABLE_DEFAULTS,
            name: 'BUILDER TABLE DEFAULTS',
            title: 'Builder Defaults',
            exact: true,
            component: BuilderTableDefaults,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Builder Defaults', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.SITE_SETTINGS,
            name: 'SITE SETTINGS',
            title: 'Site Settings',
            exact: true,
            component: Sites,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Sites', path: '/', active: true },
            ],
        },
        // {
        //     path: COMMON_PATH.COMMUNICATION_SETTINGS,
        //     name: 'COMMUNICATION SETTINGS',
        //     title: 'Communication Settings',
        //     exact: true,
        //     component: Communications,
        //     route: PrivateRoute,
        //     breadcrumbs: [
        //         { label: 'System Settings', path: COMMON_PATH.SYSTEM_SETTINGS, active: false },
        //         { label: 'Communications', path: '/', active: true },
        //     ],
        // },
        {
            path: COMMON_PATH.REPORT_SETTINGS,
            name: 'REPORT SETTINGS',
            title: 'Report Settings',
            exact: true,
            component: Reports,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Reports', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.ANALYTICS_SETTINGS,
            name: 'ANALYTICS SETTINGS',
            title: 'Analytics Settings',
            exact: true,
            component: AnalyticsSettings,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'Analytics', path: '/', active: true },
            ],
        },
        {
            path: COMMON_PATH.API_KEY,
            name: 'API KEY',
            title: 'API Key',
            exact: true,
            component: ManageapiKey,
            route: PrivateRoute,
            breadcrumbs: [
                {
                    label: 'System Settings',
                    path: COMMON_PATH.SYSTEM_SETTINGS,
                    active: false,
                },
                { label: 'API Key', path: '/', active: true },
            ],
        },
    ],
};
