// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { cpGetAllInitialFolder, cpPreviewImage, cpFolderReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import TreeView from '../../../components/treeview';
import { useForm } from 'react-hook-form';
import { openNode } from '../../../components/treeview/react-simple-jstree';
import { manageTreeData, treeLimit } from '../../../helpers/genericFunction';
import axios from 'axios';

const SelectTemplateWithPreview = (props) => {
    const [movedPath, setMovedPath] = useState(null);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const { modalDispatch } = useModalContext();
    const [initialLoad, setInitialLoad] = useState(true);
    const [parentId, setParentId] = useState(true);
    const [template, setTemplate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [changedTree, setChangedTree] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);

    const { initialList, previewImage } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolder,
        previewImage: state?.cpFolder?.cpPreview,
    }));

    const { handleSubmit } = useForm({ mode: 'onBlur' });

    useEffect(() => {
        localStorage.removeItem('SelectTemplatePreviewModalJstreeRendaring');
        setTimeout(() => {
            getInitialFolderList();
        }, 1000);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (changedTree && parentId) {
            setChangedTree(false);
            openNode(parentId);
            let itag = document?.querySelector(`[id="${parentId}"] i`);
            if (itag) {
                itag.classList.add('loading');
                setTimeout(() => itag.classList.remove('loading'), 1000);
            }
        }
    }, [changedTree, parentId]);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.getElementById(parentId);
        let eleId = document.querySelector('.card-body');
        if (ele && !eleId.children[0].classList.contains('.changeLocation'))
            ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        const cancelToken = axios.CancelToken.source();
        setCancelTokenSource(cancelToken);
        dispatch(cpGetAllInitialFolder({ parentId, processId: 12, cancelToken }));
    };

    useEffect(() => {
        if (initialList?.data) {
            setLoading(false);
            dispatch(cpFolderReset());
            async function createInitialData() {
                // await manageTreeData(data);
                await manageTreeData(
                    initialList?.data,
                    setData,
                    data,
                    'SelectTemplatePreviewModalJstreeRendaring',
                    movedPath,
                    setMovedPath,
                    getInitialFolderList
                );
            }
            if (data.length > 0) {
                setLoading(false);
                createInitialData(initialList?.data);
            } else {
                const ele = document.getElementById('#');
                ele?.getElementsByClassName('jstree-icon jstree-ocl')[0]?.classList.add('loading');

                initialList?.data?.forEach((el) => {
                    if (el?.text === 'RECYCLE BIN') return;
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                setData([...data]);
            }

            let latestIndex = 0;
            latestIndex = loadLatestPallate(latestIndex, initialList);
            if (initialList?.data[latestIndex]?.child && initialLoad) {
                getInitialFolderList(initialList?.data[latestIndex].id);
                setParentId(initialList?.data[latestIndex].id);
            } else if (!initialList?.data[latestIndex]?.child) {
                setInitialLoad(false);
                setTimeout(() => {
                    setParentId(null);
                }, 200);
            }
            dispatch(cpFolderReset());
        }
        //eslint-disable-next-line
    }, [initialList]);

    const loadLatestPallate = (latestIndex, initialList) => {
        if (initialList?.data[latestIndex]?.name?.includes('PRIMARY - ')) {
            let year = 0;
            initialList?.data.map((element, index) => {
                let str = initialList?.data[index]?.name?.replace('PRIMARY - ', '');
                if (str && year < Number(str) && initialList?.data[index]?.child) {
                    year = Number(str);
                    latestIndex = index;
                }
            });
        }
        return latestIndex;
    };

    // const manageTreeData = (list) => {
    //     for (let i = 0; i < initialList?.data?.length; i++) {
    //         const el = initialList?.data[i];
    //         for (let j = 0; j < list?.length; j++) {
    //             const d = list[j];
    //             if (el?.parentId === d?.id) {
    //                 d.child = false;
    //                 d.children.push({
    //                     id: el.id,
    //                     parent_id: el.parentId,
    //                     text: el?.text || el?.name,
    //                     icon: el.icon,
    //                     children: [],
    //                     child: el.child,
    //                     adminId: el.adminId,
    //                     assetId: el.assetId,
    //                     brandId: el.brandId,
    //                     fkRoleId: el.fkRoleId,
    //                     fkOrgId: el.fkOrgId,
    //                     fkPaletteId: el.fkPaletteId,
    //                     userId: el.userId,
    //                     assetRootNodeId: el.assetRootNodeId,
    //                     permissionbyId: el.permissionbyId,
    //                     canBeDeleted: el.canBeDeleted,
    //                 });
    //                 const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
    //                 const ids = initialList?.data.map((d) => d.id);
    //                 const newList = filterArry.filter((f) => ids.indexOf(f.id) !== -1);
    //                 d.children = [...newList];
    //             } else {
    //                 if (d?.children?.length > 0) {
    //                     manageTreeData(d?.children);
    //                 }
    //             }
    //         }
    //     }
    //     setData([...list]);
    // };

    function handleGetList(e, list) {
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
        if (e?.type === 'changed' && list) {
            setChangedTree(true);
            // let itag = document?.querySelector(`[id="${list?.node?.id}"] i`);
            // if (itag && list?.event && list?.node?.icon !== 'glyphicon glyphicon-text-background-cp') {
            //     itag.classList.add('loading');
            // } else if (itag && list?.event === undefined) {
            //     itag?.classList?.remove('loading');
            // }
        }
        if (
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
            list?.node?.original?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable'
        ) {
            setTemplate({ ...list?.node?.original });
            sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_LOADER');
            dispatch(cpPreviewImage({ cpFolderViewId: list?.node?.original?.id }));
        } else {
            setTemplate(null);
        }
    }

    const onSubmit = () => props?.data?.handleSelectTemplate(template);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <p>What panel template would you like to use?</p>
            <Row>
                <Col lg={6}>
                    <Card className="select-template-modal">
                        <Card.Body>
                            {loading && (
                                <div className="jstree-default">
                                    <i className="loading jstree-icon jstree-ocl" />
                                    <span className="loading-text">Loading...</span>
                                </div>
                            )}
                            <TreeView data={data} handleChange={handleGetList} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6} className="px-1">
                    <Card>
                        <Card.Body>
                            <h5>Preview:</h5>
                            <img
                                src={previewImage?.data ? previewImage?.data?.imageSource : ''}
                                alt=""
                                className="w-100"
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Select" variant="primary" type="submit" disabled={!template?.id} />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        cancelTokenSource.cancel('Request canceled by the user.');
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </form>
    );
};

export default SelectTemplateWithPreview;
