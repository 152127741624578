// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { ReportPrintBridgeValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import {
    reportPrintBridgeFetch,
    reportPrintBridgeUpdate,
    reportPrintBridgeReset,
    reportPrintBridgeSoftDelete,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import service from '../../../../constants/service.constant';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';

const ReportprintBridgeModal = (props) => {
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    const { paletteList, update, fetch, deleted } = useSelector((state) => ({
        paletteList: state?.palette?.list?.data,
        update: state?.reportPrintBridge?.update,
        fetch: state?.reportPrintBridge?.fetch,
        // deleted: state?.reportPrintBridge?.softDelete,
    }));

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: ReportPrintBridgeValidation, mode: 'onBlur' });
    const watchPalette = watch();
    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);
    const [printbridgeTemplatesId, setPrintbridgeTemplatesId] = useState();

    const onSubmit = (data) => {
        dispatch(
            reportPrintBridgeUpdate({
                fkTemplateId: props?.data?.original?.id,
                fkPaletteId: data?.paletteId,
                orgId: props?.data?.original?.fkOrgId,
                template: data?.template,
                colImageHeightSize: Number(data?.colImageHeightSize),
                colImageWidthSize: Number(data?.colImageWidthSize),
            })
        );
    };

    useEffect(() => {
        if (watchPalette?.paletteId && watchPalette?.paletteId !== '0') {
            dispatch(
                reportPrintBridgeFetch({
                    templatesId: props?.data?.original?.id,
                    paletteId: watchPalette?.paletteId,
                    orgId: props?.data?.original?.fkOrgId,
                })
            );
        }
    }, [watchPalette?.paletteId]);

    useEffect(() => {
        if (watchAll && watchAll.template !== '' && watchAll.paletteId !== '0') {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    useEffect(() => {
        if (update) {
            dispatch(reportPrintBridgeReset());
            props.hideModal();
        }
        if (fetch && fetch?.code === service.success) {
            setValue('template', fetch.data.template);
            setValue('colImageHeightSize', fetch.data.colImageHeightSize);
            setValue('colImageWidthSize', fetch.data.colImageWidthSize);
            setPrintbridgeTemplatesId(fetch.data.id);
        }
    }, [update, fetch]);
    // useEffect(() => {
    //     if (deleted) {
    //         modalDispatch({
    //             type: modalActions.CLOSE_MODAL,
    //         });
    //         dispatch(
    //             brandListPbSite({
    //                 orgId: orgFetch?.id,
    //                 isAnd: true,
    //                 page: list?.data?.content?.length > 1 ? list?.data?.number : list?.data?.number - 1,
    //                 size: pageSize,
    //                 // name: value,
    //                 //sort: ['name'],
    //                 sortObj: sortObj,
    //             })
    //         );
    //     }
    // }, [deleted]);

    const toggle = () => {
        props.hideModal();
        dispatch(reportPrintBridgeReset());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col
                    md={fetch && fetch?.code === service.success ? 8 : 6}
                    className={fetch && fetch?.code === service.success ? 'change-log-custom-style' : ''}>
                    <FormInput
                        type="select"
                        id="paletteId"
                        className="custom-select"
                        label="Palette *"
                        register={register}
                        errors={errors}>
                        <option value="0">Select</option>
                        {paletteList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkPaletteId}>
                                    {item?.colPaletteName}
                                </option>
                            );
                        })}
                    </FormInput>
                    {fetch && fetch?.code === service.success && (
                        <ButtonElement
                            icon={
                                <i
                                    className={
                                        'mdi icon-large change-log-icon ' + process.env.REACT_APP_CHANGE_LOG_ICON
                                    }></i>
                            }
                            className={
                                process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === true ||
                                process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === 'true'
                                    ? 'd-none'
                                    : 'action-icon hover-primary custom-svg change-log'
                            }
                            tooltip="Change Log"
                            onClick={() => {
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) =>
                                            modalDispatch({
                                                type: modalActions.CLOSE_MODAL,
                                            }),
                                        modalVariant: 'primary',
                                        modalDataKey: ['changeLog', 'changeLogCommonModulePlatform'],
                                        data: {
                                            changeLogObj: {
                                                key: `fkPrintBridgeTemplateForReportId`,
                                                value: fetch?.data?.id,
                                                orgId: props?.data?.original?.fkOrgId,
                                            },
                                        },
                                    },
                                });
                            }}
                        />
                    )}
                </Col>
                {fetch?.data?.isDeleted === true && <p className="mb-0 text-danger">(SOFT DELETED)</p>}
                <Col lg={12}>
                    <FormInput
                        className="custom-textarea mb-3 mt-3"
                        type="textarea"
                        as="textarea"
                        id="template"
                        label="Html *"
                        errors={errors}
                        register={register}
                        height="true"
                        disabled={fetch?.data?.isDeleted === true}
                    />
                </Col>

                <Row className="pb-0">
                    <Col md={6}>
                        <FormInput
                            type="number"
                            id="colImageWidthSize"
                            label="Image Width"
                            register={register}
                            negative="false"
                            defaultValue={''}
                            disabled={fetch?.data?.isDeleted === true}
                        />
                    </Col>
                    <Col md={6}>
                        <FormInput
                            type="number"
                            id="colImageHeightSize"
                            label="Image Height"
                            register={register}
                            negative="false"
                            defaultValue={''}
                            disabled={fetch?.data?.isDeleted === true}
                        />
                    </Col>
                </Row>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Save"
                    variant="primary"
                    type="submit"
                    disabled={!isValidated || fetch?.data?.isDeleted === true}
                />
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    type="button"
                    className={printbridgeTemplatesId && fetch?.data?.isDeleted === false ? '' : 'd-none'}
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) =>
                                    dispatch(
                                        reportPrintBridgeSoftDelete({
                                            orgId: fetch?.data?.orgId,
                                            printbridgeTemplatesId: printbridgeTemplatesId,
                                        })
                                    ),
                                modalVariant: 'danger',
                                modalDataKey: ['platformAdmin', 'deleteActionsPA'],
                                data: {
                                    name: 'softDeleteSitePb',
                                    title: 'Delete Print Bridge',
                                    message: 'Are you sure you want to delete this Print Bridge?',
                                },
                            },
                        })
                    }
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default ReportprintBridgeModal;
