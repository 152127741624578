import { getAllPathIds, getInstance } from '../../components/treeview/react-simple-jstree';

export const convertDaysToMinutes = (days) => {
    return days * 24 * 60;
};

export const crmInlineMandatoryChange = (e, fetch, setDisable) => {
    let obj = fetch?.content?.find((f) => f?.colOvrtureFieldName === e?.target?.name);
    if (e && obj?.colMandatory && !obj?.colDefaultValue) {
        if (e.target.value === '') {
            e.target.classList.add('is-invalid');
            e.target.parentNode.querySelector('.invalid-feedback').innerText = 'Required.';
        } else {
            e.target.classList.remove('is-invalid');
            e.target.parentNode.querySelector('.invalid-feedback').innerText = '';
        }
    } else if (
        e?.target?.name === 'fundReferenceNo' ||
        e?.target?.name === 'fkImpactTypeId' ||
        e?.target?.name === 'colFundReferenceNo' ||
        e?.target?.name === 'colFpReportYearFinance' ||
        e?.target?.name === 'colFundReferenceNo1' ||
        e?.target?.name === 'colFpReportYearFinance1' ||
        e?.target?.name === 'colFundReferenceNo2' ||
        e?.target?.name === 'colFpReportYearFinance2'
    ) {
        if (e.target.value === '') {
            e.target.classList.add('is-invalid');
            e.target.parentNode.querySelector('.invalid-feedback').innerText = 'Required.';
        } else {
            e.target.classList.remove('is-invalid');
            e.target.parentNode.querySelector('.invalid-feedback').innerText = '';
        }
    }

    setDisable(false);
    setTimeout(() => {
        let array = fetch?.content?.filter((f) => f?.colMandatory === true && f?.colDefaultValue === '');
        for (let index = 0; index < array?.length; index++) {
            const element = array[index];
            let val = document?.getElementsByName(element?.colOvrtureFieldName)[0]?.value;
            if (val === '') {
                setDisable(true);
                break;
            } else if (
                document?.getElementsByName('fundReferenceNo')[0]?.value === '' ||
                document?.getElementsByName('fkImpactTypeId')[0]?.value === '' ||
                document?.getElementsByName('colFundReferenceNo')[0]?.value === '' ||
                document?.getElementsByName('colFpReportYearFinance')[0]?.value === ''
                //document?.getElementsByName('colFundReferenceNo1')[0]?.value === '' ||
                //document?.getElementsByName('colFpReportYearFinance1')[0]?.value === '' ||
                //document?.getElementsByName('colFundReferenceNo2')[0]?.value === '' ||
                //document?.getElementsByName('colFpReportYearFinance2')[0]?.value === ''
            ) {
                setDisable(true);
                break;
            }
        }
    }, 500);
};

export const treeLimit = 15000;

export const removeManageTreeId = (AssetsJstreeRendaring, id) => {
    const clonedArray_ = JSON.parse(localStorage.getItem(AssetsJstreeRendaring)) || [];
    if (clonedArray_?.find((f) => f?.id?.toString() === id?.toString())) {
        let index = clonedArray_?.findIndex((f) => f.id?.toString() === id?.toString());
        clonedArray_.splice(index, 1);
    }
    localStorage.setItem(AssetsJstreeRendaring, JSON.stringify(clonedArray_));
};

export const getIndex = (id, AssetsJstreeRendaring) => {
    let isModalOpen = document?.querySelector(`.visually-visible-modal.show .jstree [id="${id}"]`);
    let elemnt_ = isModalOpen ? isModalOpen : document.getElementById(id);
    let elemntArray_ = elemnt_.parentNode.childNodes;
    var index_ = Array.from(elemntArray_).indexOf(elemnt_);
    const clonedArray_ = JSON.parse(localStorage.getItem(AssetsJstreeRendaring)) || [];
    if (!clonedArray_?.find((f) => f.id === elemnt_.id)) {
        clonedArray_.push({ index: index_, id: elemnt_.id });
    }
    localStorage.setItem(AssetsJstreeRendaring, JSON.stringify(clonedArray_));
    return index_;
};

const openPath = (elemnt_, movedPath, setMovedPath, getInitialFolderList, initialListData) => {
    if (movedPath) {
        setTimeout(() => {
            if (movedPath?.path?.length > 0) {
                let prevId = initialListData[0]?.parentId?.toString();
                let isModalOpen = document?.querySelector(`.visually-visible-modal.show .jstree [id="${prevId}"]`);
                let element = isModalOpen ? isModalOpen : document.getElementById(prevId);
                element?.classList?.contains('jstree-closed') &&
                    element.querySelector('.jstree-icon.jstree-ocl').click();
                let index = movedPath?.path.findIndex((f) => f === prevId);
                let newIndex = index + 1;
                if (newIndex <= movedPath?.path?.length - 1) {
                    getInitialFolderList(movedPath?.path[newIndex]);
                    newIndex === movedPath?.path?.length - 1 && setMovedPath(null);
                }
            }
        }, 1000);
        // setTimeout(() => {
        //     let indexPathMove = movedPath.path.findIndex((f) => f === elemnt_.id);
        //     let newIndexForPath = indexPathMove + 1;
        //     let id;
        //     if (movedPath?.path?.length > newIndexForPath && movedPath?.path[newIndexForPath]) {
        //         id = movedPath?.path[newIndexForPath];
        //         let isModalOpen = document?.querySelector(`.visually-visible-modal.show .jstree [id="${id}"]`);
        //         let element = isModalOpen ? isModalOpen : document.getElementById(id);
        //         element?.classList?.contains('jstree-closed') &&
        //             element.querySelector('.jstree-icon.jstree-ocl').click();
        //     }
        //     if (newIndexForPath === movedPath?.path?.length - 1) {
        //         setMovedPath(null);
        //         getInitialFolderList && getInitialFolderList(id);
        //     }
        // }, 1000);
    }
};

const getIndexFromDocument = (id, index, clonedArray, pathArray, AssetsJstreeRendaring) => {
    let index__ = null;
    let isModalOpen = document?.querySelector(`.visually-visible-modal.show .jstree [id="${id}"]`);
    let elemnt__ = isModalOpen ? isModalOpen : document?.getElementById(id);
    let elemntArray__ = elemnt__?.parentNode?.childNodes;
    index__ = Array.from(elemntArray__).indexOf(elemnt__);
    clonedArray.find((f) => f.id === pathArray[index]).index = index__;
    localStorage.setItem(AssetsJstreeRendaring, JSON.stringify(clonedArray));
    return index__;
};

export const manageTreeData = (
    initialListData,
    setData,
    data,
    AssetsJstreeRendaring,
    movedPath,
    setMovedPath,
    getInitialFolderList,
    props
) => {
    if (initialListData?.length > 0) {
        let isModalOpen = document?.querySelector(
            `.visually-visible-modal.show .jstree [id="${initialListData[0]?.parentId}"]`
        );
        let elemnt_ = isModalOpen ? isModalOpen : document?.getElementById(initialListData[0]?.parentId);
        if (!elemnt_) {
            return;
        }
        let elemntArray_ = elemnt_?.parentNode?.childNodes;
        var index_ = Array.from(elemntArray_).indexOf(elemnt_);
        const clonedArray_ = JSON.parse(localStorage.getItem(AssetsJstreeRendaring)) || [];
        if (!clonedArray_?.find((f) => f.id === elemnt_.id)) {
            clonedArray_.push({ index: index_, id: elemnt_.id });
        }
        localStorage.setItem(AssetsJstreeRendaring, JSON.stringify(clonedArray_));
        let pathArray = getAllPathIds(initialListData[0]?.parentId);
        let obj = null;
        for (let index = 0; index < pathArray?.length; index++) {
            const clonedArray = JSON.parse(localStorage.getItem(AssetsJstreeRendaring));
            let new_ = clonedArray?.find((f) => f.id === pathArray[index]);
            let index_ = new_?.index;
            if (!index_) {
                index_ = getIndex(pathArray[index], AssetsJstreeRendaring);
            }
            if (index === 0) {
                obj = data[index_];
            } else {
                if (obj?.children[index_]) {
                    obj = obj.children[index_];
                } else {
                    index_ = getIndexFromDocument(new_?.id, index, clonedArray, pathArray, AssetsJstreeRendaring);
                    obj = obj.children[index_];
                }
            }
            if (index === pathArray?.length - 1) {
                initialListData?.forEach((el) => {
                    if (!obj?.children?.find((f) => f?.id === el?.id)) {
                        let disabled = false;
                        if (
                            AssetsJstreeRendaring === 'SelectContentsJstreeRendaring' &&
                            props &&
                            (props?.id === el.id ||
                                el.icon === 'glyphicon glyphicon-text-background-cp' ||
                                el.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
                                el.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
                                el.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable')
                        ) {
                            obj.child = false;
                            disabled = true;
                        }
                        obj.children.push({
                            id: el.id,
                            parent_id: el.parentId,
                            text: el?.text || el?.name,
                            icon: el.icon,
                            children: el.child ? [] : [],
                            child: el.child,
                            adminId: el.adminId,
                            assetId: el.assetId,
                            brandId: el.brandId,
                            fkRoleId: el.fkRoleId,
                            fkOrgId: el.fkOrgId,
                            fkPaletteId: el.fkPaletteId,
                            userId: el.userId,
                            assetRootNodeId: el.assetRootNodeId,
                            permissionbyId: el.permissionbyId,
                            canBeDeleted: el.canBeDeleted,
                            fkCpRootNodeId: el.fkCpRootNodeId,
                            cpContentId: el.cpContentId,
                            state: {
                                disabled,
                            },
                        });
                    }
                });
            }
        }
        setData([...data]);
        openPath(elemnt_, movedPath, setMovedPath, getInitialFolderList, initialListData);
    }
};

export const removeNullUndefineValueFromPayloadObject = (payload) => {
    let newObj = {};
    for (const key in payload) {
        if (payload[key] !== null && payload[key] !== undefined) {
            newObj[key] = payload[key];
        }
    }
    return newObj;
};

export const arraysAreEqual = (array1, array2) => {
    if (!array1 || !array2 || array1.length !== array2.length) {
        return false;
    }
    const sortedArray1 = [...array1].sort();
    const sortedArray2 = [...array2].sort();
    for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
            return false;
        }
    }
    return true;
};
