import tinymce from 'tinymce';
import $ from 'jquery';
/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';
/* A theme is also required */
import 'tinymce/themes/silver';
/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';
/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import { lockedAndUnlocked } from '../notification/lockUnlock';
import { getEditorStorage, setEditorChanges } from '../storage';
import { RedirectTo } from '../../../routes/role/path';
import { cleanupForSectionId } from '../migration/TenantMigration';
import { getTenantOrgId } from '../../retainHistory';

document?.addEventListener('click', (e) => {
    if (window.location.pathname === RedirectTo('/content-panel-edit')) lockedAndUnlocked(e, tinymce);
});

export const tinymceInlineInit = (notification, data) => {
    cleanupForSectionId();
    tinymceInlineInitFactory('.inline-textEditor:not(.non-editable)', notification, data);
};

export const tinymceInlineInitFactory = (param, notification, data) => {
    tinymce.init({
        license_key: process.env.REACT_APP_TINY_TOKEN,
        selector: param,
        ...data?.initObj,
        //icons: 'material',
        menubar: false,
        inline: data?.isPlatform ? false : true,
        plugins: ['lists', 'wordcount', 'autolink', 'link'],
        contextmenu: '',
        //plugins: ['lists', 'powerpaste', 'wordcount', 'autolink', 'link'],
        toolbar: 'bold italic underline bullist numlist link unlink wordCountHolder',
        toolbar_items_size: 'small',
        browser_spellcheck: true,
        link_assume_external_targets: 'false',
        paste_webkit_styles: 'none',
        paste_data_images: false,
        paste_as_text: true,
        paste_merge_formats: false,
        paste_block_drop: false,
        // paste_word_valid_elements: 'b,strong,i,em,h1,h2',
        // paste_retain_style_properties: 'color font-size',
        default_link_target: '_blank',
        extended_valid_elements: 'a[href|target=_blank]',
        // forced_root_block: false,
        // force_br_newlines: true,
        // force_p_newlines: false,
        //forced_root_block: 'span',
        //valid_elements: 'a[href|target=_blank]',
        urlconverter_callback: function (url, node, on_save, name) {
            if (!url?.toLocaleLowerCase()?.includes('mailto:') && url.search('http') === -1) {
                url = 'http://' + url;
            }
            // Return new URL
            return url;
        },
        paste_preprocess: function (plugin, args) {
            const contentAreaContainer = activeEditorContainer();
            let isPlatformTiny = tinymce?.activeEditor?.contentAreaContainer?.closest('.areaTinyInputEditor');
            var maxEditorLength = Number(
                contentAreaContainer?.attributes?.maxlength?.value ||
                    isPlatformTiny?.querySelector('.tox-tinymce-textarea')?.attributes?.maxlength?.value
            );
            var activeEditorCount = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount();
            var selectedlength = 0;
            if (tinymce.activeEditor.selection.getContent() !== '') {
                selectedlength = tinymce.activeEditor.selection
                    .getContent({
                        format: 'text',
                    })
                    .toString()
                    .replace(/\n|\r/g, '').length;
            }
            var copyTextLen = 0;
            if (args.content !== '') {
                copyTextLen = getTextLength(args.content);
            }
            if (activeEditorCount - selectedlength < maxEditorLength) {
                if (activeEditorCount + copyTextLen - selectedlength > maxEditorLength) {
                    var allowedLength = maxEditorLength - activeEditorCount + selectedlength;
                    if (contentAreaContainer?.classList?.contains('hard-return-block')) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(args.content, 'text/html');
                        args.content = doc?.body?.textContent?.replaceAll(/\n|\r/g, '').slice(0, allowedLength);
                    } else {
                        args.content = trimHtml(args.content, allowedLength);
                    }
                    notification?.setAutoClose(false);
                    notification?.Toast?.warning({
                        title: 'Warning',
                        description:
                            'Pasted content truncated to fit within available character count. Please check content box.',
                        button: 'Ok',
                    });
                } else if (contentAreaContainer?.classList?.contains('hard-return-block')) {
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(args.content, 'text/html');
                    args.content = doc?.body?.textContent?.replaceAll(/\n|\r/g, '');
                }
            } else {
                args.stopPropagation();
                args.preventDefault();
            }
        },
        setup: function (editor) {
            editor.ui.registry.addButton('wordCountHolder', {
                text: '',
                onAction: () => {},
            });
            editor.on('click', function (e) {
                setCharCount(e);
            });
            editor.on('ExecCommand', function (e) {
                let greetingMsg = document?.querySelector('.modal .default-greeting-message');
                if (greetingMsg) {
                    if (e.command === 'mceLink') {
                        greetingMsg.parentNode.removeAttribute('tabindex');
                    } else {
                        greetingMsg.parentNode.setAttribute('tabindex', '-1');
                    }
                }
                if (tinymce?.activeEditor?.contentAreaContainer?.closest('.areaTinyInputEditor')) {
                    setCharCount(e);
                }
                const contentAreaContainer = activeEditorContainer();
                if (
                    contentAreaContainer?.attributes?.maxlength &&
                    !contentAreaContainer?.classList?.contains('non-editable')
                ) {
                    setCharCount(e);
                } else if (contentAreaContainer?.classList?.contains('non-editable')) {
                    tinymce.EditorManager.execCommand('mceRemoveEditor', true, e.target.id);
                    e.target.container.style.display = 'none';
                }
            });
            editor.on('keyup keypress keydown change', function (e) {
                if (e.type === 'change') {
                    setChatArialabel(e);
                    setEditorChanges(true);
                    data?.getContentChange(editor.getContent(), editor?.id);
                }
                if (e.code === 'Backspace') return onDeleteValidation(e);
                if (e.type === 'keypress') return negativeValidation();
                if (e.type === 'keydown') {
                    if (e.key !== 'ArrowRight' && (e.key === ' ' || e.keyCode === 32)) {
                        let eleCount = Number(tinymce.activeEditor.plugins.wordcount.body.getCharacterCount());
                        let maxlength = Number(tinymce.activeEditor.contentAreaContainer.attributes.maxlength.value);
                        let isPlatformTiny =
                            tinymce?.activeEditor?.contentAreaContainer?.closest('.areaTinyInputEditor');
                        if (isPlatformTiny) {
                            eleCount = Number(tinymce.activeEditor.plugins.wordcount.body.getCharacterCount());
                            maxlength = Number(
                                isPlatformTiny?.querySelector('.tox-tinymce-textarea')?.attributes?.maxlength?.value
                            );
                        }
                        maxlength - eleCount < 1 && e.preventDefault();
                    }
                    return hardReturnBlock(e);
                }
                if (e.type === 'keyup') setCharCount(e);
            });
            editor.on('blur', function (e) {
                if (e?.target?.contentAreaContainer?.getAttribute('onblur')?.trim() === 'copytext(this);') {
                    window?.copytext && window.copytext(e.target.contentAreaContainer);
                }
                if (e?.target?.contentAreaContainer?.getAttribute('onblur')?.trim() === 'subHeadingCopyText(this);') {
                    window?.subHeadingCopyText && window.subHeadingCopyText(e.target.contentAreaContainer);
                }
                removeTox();
            });
            editor.on('dragstart dragover dragend drop', (e) => {
                e.preventDefault();
                e.stopPropagation();
            });
        },
    });
};

export const tinymceRemove = () => tinymce.remove();

const getTextLength = (htmlString) => {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    let textContent = tempElement.textContent || tempElement.innerText || '';
    return textContent.replace(/\n|\r/g, '').length;
};

const trimHtml = (htmlString, limit) => {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    const extractText = (node, charLimit) => {
        let textLength = 0;
        let truncatedHtml = '';
        for (let i = 0; i < node.childNodes.length; i++) {
            let child = node.childNodes[i];
            if (child.nodeType === Node.TEXT_NODE) {
                let remainingChars = charLimit - textLength;
                if (remainingChars <= 0) {
                    break;
                }
                let textContent = child.textContent.slice(0, remainingChars);
                textLength += textContent.length;
                truncatedHtml += textContent;
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                let result = extractText(child, charLimit - textLength);
                textLength += result.textLength;
                truncatedHtml += `<${child.tagName.toLowerCase()}${Array.from(child.attributes)
                    .map((attr) => ` ${attr.name}="${attr.value}"`)
                    .join('')}>${result.truncatedHtml}</${child.tagName.toLowerCase()}>`;
                if (textLength >= charLimit) {
                    break;
                }
            }
        }
        return { textLength, truncatedHtml };
    };
    let result = extractText(tempElement, limit);
    return result.truncatedHtml;
};

const onDeleteValidation = (e) => {
    if (e.target.innerText.replace('\n', '') === window.getSelection().toString()) {
        e.stopPropagation();
        e.preventDefault();
        e.target.children[0].innerText = '';
        e.target.children[0].innerHTML += '<br>';
    }
    if (e.target.innerText.replace('\n', '') === '') {
        e.stopPropagation();
        e.preventDefault();
    }
    setCharCount(e);
};

const activeEditorContainer = () => {
    let tinymceactiveEditor = tinymce?.activeEditor?.contentAreaContainer;
    let isPlatformTiny = tinymce?.activeEditor?.contentAreaContainer?.closest('.areaTinyInputEditor');
    if (isPlatformTiny) {
        tinymceactiveEditor = isPlatformTiny
            ?.querySelector('iframe')
            ?.contentWindow?.document?.querySelector('#tinymce');
    }
    return tinymceactiveEditor;
};

const negativeValidation = () => {
    let isPlatformTiny = tinymce?.activeEditor?.contentAreaContainer?.closest('.areaTinyInputEditor');
    let getCharacterCount = Number(tinymce.activeEditor.plugins.wordcount.body.getCharacterCount());
    let selectedChar = tinymce?.activeEditor?.selection
        ?.getContent({ format: 'text' })
        ?.toString()
        ?.replace(/\n|\r/g, '')?.length;
    if (window.getSelection() && selectedChar > 0) getCharacterCount = getCharacterCount - selectedChar;
    return Number(
        activeEditorContainer()?.attributes?.maxlength?.value ||
            isPlatformTiny?.querySelector('.tox-tinymce-textarea')?.attributes?.maxlength?.value
    ) -
        Number(getCharacterCount) >
        0
        ? true
        : false;
};

const hardReturnBlock = (e) => {
    const contentAreaContainer = activeEditorContainer();
    let checkForHardReturnFuntionality = contentAreaContainer.classList;
    let getHardReturnFunctionalityDisableClasses = getEditorStorage('checkforHardReturnVarInit');
    //Hard return block for specific classes
    for (let i = 0; i < getHardReturnFunctionalityDisableClasses?.length; i++) {
        if (checkForHardReturnFuntionality.contains(getHardReturnFunctionalityDisableClasses[i]) && e.key === 'Enter') {
            return false;
        } else {
            setCharCount(e);
        }
    }
};

const setChatArialabel = (e) => {
    const textArialabel = e?.target?.contentAreaContainer?.textContent;
    let targetElement = e?.target?.contentAreaContainer?.closest('a.documentEditor');
    targetElement && targetElement.setAttribute('aria-label', textArialabel);
};

const setCharCount = (e) => {
    // tinymce
    const contentAreaContainer = activeEditorContainer();
    let isPlatformTiny = tinymce?.activeEditor?.contentAreaContainer?.closest('.areaTinyInputEditor');
    if (isPlatformTiny) {
        let eleCount = Number(tinymce.activeEditor.plugins.wordcount.body.getCharacterCount());
        let maxlength = Number(isPlatformTiny?.querySelector('.tox-tinymce-textarea')?.attributes?.maxlength?.value);
        var countbtn =
            tinymceInlineActiveToolbar?.querySelector('.tox-tbtn:last-child') ||
            isPlatformTiny?.querySelector('.text-limit');
        let tinymceactiveEditor = tinymce?.activeEditor?.contentAreaContainer?.innerText;
        if (isPlatformTiny) {
            tinymceactiveEditor = isPlatformTiny
                .querySelector('iframe')
                .contentWindow.document.querySelector('#tinymce').innerText;
        }
        if (tinymceactiveEditor === '\n' || tinymceactiveEditor === '') {
            countbtn.innerText = isPlatformTiny ? maxlength + ' characters' : maxlength;
        } else if (!checkHardReturn && e.key === 'Enter') {
            countbtn.innerText = isPlatformTiny ? maxlength - eleCount + ' characters' : maxlength - eleCount;
        } else if (!checkHardReturn && e.key === 'Backspace') {
            if (eleCount === 1 && contentAreaContainer.classList.contains('report-update-name')) {
                contentAreaContainer.innerText = ' ';
                countbtn.innerText = isPlatformTiny ? maxlength - eleCount + ' characters' : maxlength - eleCount;
            } else {
                countbtn.innerText = isPlatformTiny ? maxlength - eleCount + ' characters' : maxlength - eleCount;
            }
        } else {
            countbtn.innerText = isPlatformTiny ? maxlength - eleCount + ' characters' : maxlength - eleCount;
        }
    } else {
        var tinymceInlineActiveToolbar = document.querySelector('.tox-tinymce-inline:not([style*="display: none"])');

        let maxlength = Number(tinymce.activeEditor.contentAreaContainer.attributes.maxlength.value);
        let eleCount = Number(tinymce.activeEditor.plugins.wordcount.body.getCharacterCount());
        var checkHardReturn =
            tinymce.activeEditor.contentAreaContainer.classList.contains('hard-return-block') && e.key === 'Enter';
        let getLinkFunctionalityDisableClasses = getEditorStorage('checkIfLinkFunctionalityAvailable');
        var LinkFunctionActive = true;

        tinymce.activeEditor.contentAreaContainer.classList.forEach((item) => {
            if (getLinkFunctionalityDisableClasses?.includes(item)) {
                LinkFunctionActive = false;
                return;
            }
        });
        if (
            tinymce.activeEditor.contentAreaContainer?.parentElement?.classList?.contains(
                'inner-positioner-header-panel-heading'
            )
        ) {
            LinkFunctionActive = false;
        }

        if (
            Number(getTenantOrgId()) === 67 &&
            tinymce.activeEditor.contentAreaContainer.classList?.contains('Link_text')
        ) {
            LinkFunctionActive = true;
        }

        if (tinymceInlineActiveToolbar == null) {
            return;
        }

        if (tinymce?.activeEditor?.contentAreaContainer?.classList?.contains('report-update-name')) {
            LinkFunctionActive = false;
        }
        if (LinkFunctionActive === false || LinkFunctionActive === 'false') {
            var linkbtn = tinymceInlineActiveToolbar.querySelector('[aria-label="Insert/edit link"]');
            linkbtn.classList.add('tox-tbtn--disabled');
            linkbtn.setAttribute('aria-disabled', 'true');
            setTimeout(() => {
                linkbtn.classList.add('tox-tbtn--disabled');
                linkbtn.setAttribute('aria-disabled', 'true');
            }, 100);
        }

        var countbtn = tinymceInlineActiveToolbar.querySelector('.tox-tbtn:last-child');

        if (
            tinymce?.activeEditor?.contentAreaContainer?.innerText === '\n' ||
            tinymce?.activeEditor?.contentAreaContainer?.innerText === ''
        ) {
            countbtn.innerText = maxlength;
        } else if (!checkHardReturn && e.key === 'Enter') {
            countbtn.innerText = maxlength - eleCount;
        } else if (!checkHardReturn && e.key === 'Backspace') {
            if (eleCount === 1 && tinymce.activeEditor.contentAreaContainer.classList.contains('report-update-name')) {
                tinymce.activeEditor.contentAreaContainer.innerText = ' ';
                countbtn.innerText = maxlength - eleCount;
            } else {
                countbtn.innerText = maxlength - eleCount;
            }
        } else {
            countbtn.innerText = maxlength - eleCount;
        }
    }
};

export const removeTox = () => {
    if (
        window.location.pathname.includes(RedirectTo('/site-edit')) ||
        window.location.pathname.includes(RedirectTo('/report-edit')) ||
        window.location.pathname.includes(RedirectTo('/content-panel-edit'))
    ) {
        let toxArray = document?.querySelectorAll('.tox.tox-tinymce');
        toxArray?.forEach((element) => {
            element.style.display = 'none';
        });
    }
};
